<template>
  <div class="front-sheet-comments">
    <CModal
      color="primary"
      :show.sync="isShowPopup"
      :closeOnBackdrop="false"
      size="lg"
    >
      <template #header>
        <h5>Comments</h5>
        <button aria-label="Close" class="close" @click="modalCallBack(false)">
          x
        </button>
      </template>
      <div class="row d-flex justify-content-center">
        <div class="col-md-11" style="max-height: 400px; overflow: auto">
          <div class="card p-3" v-for="(data, index) in comments" :key="index">
            <div class="d-flex justify-content-between align-items-center">
              <div class="align-items-center">
                <p class="font-weight-bold mb-1">
                  <span>{{ data.created_by_user.first_name }} {{data.created_by_user.surname}}</span>
                </p>
                <span class="ml-3" style="font-weight: 500">{{
                  data.comments
                }}</span>
              </div>
              <div style="min-width: 20%">
                <small style="font-weight: 600">{{
                  data.created_on | formatDate
                }}</small>
              </div>
            </div>
          </div>
          <p v-if="!comments.length" class="text-center mt-2">No Comments Available</p>
        </div>
      </div>
      <template #footer>
        <CButton color="primary" @click="modalCallBack(false)">OK</CButton>
      </template>
    </CModal>
  </div>
</template>
<script>
import m from "moment";
export default {
  props: {
    isShowPopup: {
      type: Boolean,
      default: false,
    },
    comments: {
      type: Array,
      default: [],
    },
  },
  methods: {
    modalCallBack(action, payload) {
      this.$emit("modalCallBack", action, payload);
    },
  },
  filters: {
    formatDate(date) {
      if (date) return m.utc(date).local().format("DD-MMM-YYYY hh:mm A");
      return date;
    },
  },
};
</script>