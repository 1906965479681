<template>
  <div class="frontsheet-modal">
    <div v-if="!commentsModal.isShowPopup && isShowPopup">
      <CModal
        color="primary"
        :show.sync="isShowPopup"
        :close-on-backdrop="false"
        size="lg"
      >
        <template #header>
          <h6 class="modal-title">{{ title }}</h6>
          <CButtonClose @click="modalCallBack(false)" class="text-black" />
        </template>
        <!-- <template #footer>
          <div>
            <CButton class="btn-secondary mr-2" @click="modalCallBack(false)">
              Cancel
            </CButton>
            <CButton class="btn-primary" @click="saveFrontSheet">
              Save
            </CButton>
          </div>
        </template> -->
        <template #footer-wrapper>
          <div class="border-top d-flex py-3 mx-3">
            <div class="flex-grow-1 d-flex">
              <!-- <CButton
                colour="primary"
                class="btn-link p-0 mx-2"
                v-c-tooltip="'Click to Download File'"
                @click="exportToWord"
                ><i class="fas fa-download m-0"></i
              ></CButton> -->
              <CDropdown color="primary" addMenuClasses="fs-12" :caret="false">
                <template #toggler>
                  <i
                    class="fas fa-download mx-2 text-primary cursor-pointer"
                  ></i>
                </template>
                <CDropdownItem href="javascript:void(0)" @click="exportToWord"
                  >Download as Word</CDropdownItem
                >
                <CDropdownItem href="javascript:void(0)" @click="exportToPDF"
                  >Download as PDF</CDropdownItem
                >
              </CDropdown>
            </div>
            <div class="d-flex">
              <CButton class="btn-secondary mr-2" @click="modalCallBack(false)">
                Cancel
              </CButton>
              <CButton class="btn-primary" @click="saveFrontSheet">
                Save
              </CButton>
            </div>
          </div>
        </template>
        <div id="frontsheet" ref="frontsheet">
          <div class="profile-section mb-2">
            <h6 class="text-primary">Profile</h6>
            <table class="w-100 table table-bordered">
              <colgroup>
                <col span="1" style="width: 40%" />
                <col span="1" style="width: 60%" />
              </colgroup>
              <tbody>
                <tr v-if="candidateDetail.profile_picture !== '/img/user_default_pic.png'">
                  <td colspan="2" style="text-align: center;">
                    <img 
                      :src="generateFullUrlWithToken(candidateDetail.profile_picture)" 
                      style="height: 150px; border-radius: 50%;">
                  </td>
                </tr>
                <tr v-else>
                  <td colspan="2" style="text-align: center;">
                    <div style="display: inline-block; border: 1px solid black; padding: 10px; width: 150px; height: 150px; text-align: center; background-color:  #f0f0f0;">
                      <span style="font-size: 12px; text-align: center;">PHOTO</span>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td class="col-label">Candidate</td>
                  <td>
                    <b
                      >{{ candidateDetail.first_name }}
                      {{ candidateDetail.surname }}</b
                    >
                    |
                    {{ candidateDetail.display_uid }}
                  </td>
                </tr>
                <tr v-if="candidateDetail.known_as">
                  <td class="col-label">Known As</td>
                  <td>{{ candidateDetail.known_as }}</td>
                </tr>
                <tr>
                  <td class="col-label">Job Title</td>
                  <td>
                    <b>{{ jobDetail.job_title }}</b> |
                    {{ jobDetail.organisation_name }} |
                    {{ jobDetail.display_uid }}
                  </td>
                </tr>
                <tr>
                  <td class="col-label">Current Job Title</td>
                  <td>{{ candidateDetail.current_job || "N/A" }}</td>
                </tr>
                <tr>
                  <td class="col-label">Estimated Join Date</td>
                  <td>
                    {{ candidateDetail.expected_join_date | formatDate }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="professional-info mb-2">
            <h6 class="text-primary">Professional Info</h6>
            <table class="w-100 table table-bordered">
              <colgroup>
                <col span="1" style="width: 40%" />
                <col span="1" style="width: 60%" />
              </colgroup>
              <tbody>
                <tr>
                  <td class="col-label">
                    {{
                      isPhysician
                        ? `Speciality/Sub Speciality`
                        : `Main Speciality/Other Expertise`
                    }}
                  </td>
                  <td>{{ splSub || "N/A" }}</td>
                </tr>
                <tr>
                  <td class="col-label">Qualification</td>
                  <td v-if="qualifications.length">
                    <ul class="pl-4">
                      <li
                        v-for="(qualification, index) in qualifications"
                        :key="index"
                      >
                        {{ qualification.qualification }} |
                        {{ qualification.country_name }} |
                        {{ qualification.date_obtained }}
                      </li>
                    </ul>
                  </td>
                  <td v-else>N/A</td>
                </tr>
                <tr>
                  <td class="col-label">Additional Information</td>
                  <td class="p-0">
                    <textarea
                      :value="payload.additional_information"
                      name="additional_information"
                      @input="
                        payload.additional_information = $event.target.value
                      "
                      style="width: 100%"
                    />
                    <span class="d-none">{{
                      payload.additional_information
                    }}</span>
                  </td>
                </tr>
                <tr>
                  <td class="col-label">Language Qualifications</td>
                  <td v-if="getCandidateNonMedicalQuals.length">
                    <ul class="pl-4">
                      <li
                        v-for="nonMedicalQual in getCandidateNonMedicalQuals"
                        :key="nonMedicalQual.id"
                      >
                        {{ nonMedicalQual.non_medical_qual.name }} |
                        {{ nonMedicalQual.non_medical_qual.score }}
                      </li>
                    </ul>
                  </td>
                  <td v-else>N/A</td>
                </tr>
                <tr>
                  <td class="col-label">Other Languages</td>
                  <td>{{ languages }}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="practicalities-section mb-2">
            <h6 class="text-primary">Practicalities</h6>
            <table class="w-100 table table-bordered">
              <colgroup>
                <col span="1" style="width: 40%" />
                <col span="1" style="width: 60%" />
              </colgroup>
              <tbody>
                <tr>
                  <td class="col-label">Employment(s)</td>
                  <td class="p-0">
                    <textarea
                      :value="payload.employment_s"
                      name="employment_s"
                      @input="payload.employment_s = $event.target.value"
                      style="width: 100%"
                    />
                    <span class="d-none">{{ payload.employment_s }}</span>
                  </td>
                </tr>
                <tr>
                  <td class="col-label">Registrations</td>
                  <td class="p-0">
                    <textarea
                      :value="payload.registrations"
                      name="registrations"
                      @input="payload.registrations = $event.target.value"
                      style="width: 100%"
                    />
                    <span class="d-none">{{ payload.registrations }}</span>
                  </td>
                </tr>
                <tr>
                  <td class="col-label">Gender</td>
                  <td>{{ gender }}</td>
                </tr>
                <tr>
                  <td class="col-label">Passport</td>
                  <td>{{ candidateDetail.passport_number || "N/A" }}</td>
                </tr>
                <tr>
                  <td class="col-label">Birthplace</td>
                  <td>{{ birthPlace }}</td>
                </tr>
                <tr>
                  <td class="col-label">DOB/Age</td>
                  <td>
                    {{ candidateDetail.dob | formatDate }} /
                    {{ candidateDetail.age }} Years
                  </td>
                </tr>
                <tr>
                  <td class="col-label">Status/No. of Dependents</td>
                  <td>
                    <span v-if="candidateDetail.marital_status">
                      {{ candidateDetail.marital_status }}
                      {{
                        candidateDetail.marital_status === "Married"
                          ? ` ${
                              candidateDetail.dependents || ""
                                ? `-${candidateDetail.dependents}`
                                : ""
                            }`
                          : ""
                      }}
                    </span>
                    <span v-else>N/A</span>
                  </td>
                </tr>
                <tr>
                  <td class="col-label">
                    Comments
                    <em
                      class="fas fa-comments cursor-pointer"
                      v-c-tooltip="{
                        content: `Comments`,
                        placement: `bottom`,
                      }"
                      @click="commentsModal.isShowPopup = true"
                    ></em>
                  </td>
                  <td class="p-0">
                    <textarea
                      :value="payload.comments"
                      name="comments"
                      @input="payload.comments = $event.target.value"
                      style="width: 100%"
                    />
                    <span class="d-none">{{ payload.comments }}</span>
                  </td>
                </tr>
                <tr>
                  <td class="col-label">Current Salary</td>
                  <td>
                    <span v-if="candidateDetail.current_salary">
                      {{ candidateDetail.current_salary }}
                      <span v-if="candidateDetail.current_salary_currency">{{
                        candidateDetail.current_salary_currency.currency_code
                      }}</span>
                    </span>
                    <span v-else>N/A</span>
                  </td>
                </tr>
                <tr>
                  <td class="col-label">Expected Salary</td>
                  <td>
                    <span
                      v-if="
                        candidateDetail.salary_expectation_min &&
                        candidateDetail.salary_expectation_max
                      "
                    >
                      {{ candidateDetail.salary_expectation_min }} -
                      {{ candidateDetail.salary_expectation_max }}
                      <span
                        v-if="candidateDetail.salary_expectation_currency"
                        >{{
                          candidateDetail.salary_expectation_currency
                            .currency_code
                        }}</span
                      >
                    </span>
                    <span v-else>N/A</span>
                  </td>
                </tr>
                <tr>
                  <td class="col-label">Rating of CV</td>
                  <td>
                    <Rating
                      name="rating_of_cv"
                      :value="payload.rating_of_cv"
                      :isNumberRating="true"
                      @change="handleInput"
                      :disabled="isIncustomeruserRole"
                    />
                    <span class="d-none">{{ payload.rating_of_cv }}</span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </CModal>
    </div>
    <Comments
      v-if="commentsModal.isShowPopup"
      :isShowPopup="commentsModal.isShowPopup"
      :comments="commentsModal.comments"
      @modalCallBack="commentsModalCallBack"
    />
  </div>
</template>
<script>
import Rating from "@/components/reusable/Fields/Rating";
import { mapActions, mapGetters } from "vuex";
import m from "moment";
import { isEmptyObjectCheck, isObject, generateFullUrlWithToken } from "@/helpers/helper";
import Comments from "@/containers/Frontsheet/Comments";
export default {
  props: {
    isShowPopup: {
      type: Boolean,
      default: () => false,
    },
    title: {
      type: String,
      default: () => "Frontsheet",
    },
    candidateDetail: {
      type: Object,
      default: null,
    },
    jobDetail: {
      type: Object,
      default: null,
    },
    frontSheetInfo: {
      type: Object,
      default: null,
    },
    frontSheets: {
      type: Array,
      default: [],
    },
  },
  components: {
    Rating,
    Comments,
  },
  data() {
    return {
      qualificationCollapse: false,
      splCollapse: false,
      payload: {
        additional_information: null,
        registrations: null,
        employment_s: null,
        comments: null,
        rating_of_cv: null,
      },
      commentsModal: {
        comments: [],
        isShowPopup: false,
      },
      generateFullUrlWithToken,
    };
  },
  computed: {
    ...mapGetters([
      "getCustomerId",
      "getOrgIDFromAccessToken",
      "isIncustomeruserRole",
      "getCandidateNonMedicalQuals",
    ]),
    isNurse() {
      return this.candidateDetail.candidate_type_id === 2 || false;
    },
    isPhysician() {
      return this.candidateDetail.candidate_type_id === 1 || false;
    },
    splSub() {
      if (this.candidateDetail?.sub_speciality)
        return `${this.candidateDetail?.speciality?.speciality}/${this.candidateDetail?.sub_speciality?.sub_speciality}`;
      return this.candidateDetail?.speciality?.speciality;
    },
    qualifications() {
      return (
        (this.candidateDetail.qualifications &&
          this.candidateDetail.qualifications
            .sort((a, b) => a.main - b.main)
            .sort((a, b) => b.year_awarded - a.year_awarded)
            .filter(({ qualification_name }) => qualification_name)
            .map((v) => ({
              country_name: v?.country_name,
              date_obtained: `${this.getMonthName(v?.month_awarded)} - ${
                v?.year_awarded
              }`,
              qualification: v?.qualification_name,
            }))) ||
        []
      );
    },
    specialInterests() {
      return (
        (this.candidateDetail.candidate_specialities &&
          this.candidateDetail.candidate_specialities
            .filter((v) => v.special_interest_id || v.other_special_interest)
            .map((v) => v?.special_interest?.special_interest || "Other")) ||
        []
      );
    },
    languages() {
      return (
        this.candidateDetail?.candidate_languages
          .map((v) => v?.language?.language)
          .join(", ") || "N/A"
      );
    },
    gender() {
      return this.candidateDetail?.gender?.gender || "N/A";
    },
    birthPlace() {
      return this.candidateDetail?.birth_place?.country_name || "N/A";
    },
    nonMedicalQual() {
      return this.getCandidateNonMedicalQuals.map(
        (v) => v?.non_medical_qual?.name
      );
    },
    profileImage() {
      if (this.candidateDetail?.profile_picture !== '/img/user_default_pic.png') {
        return `
          <div style="text-align: center;">
            <img src="${generateFullUrlWithToken(this.candidateDetail?.profile_picture)}">
          </div>
        `;
      } else {
        return `
        <div style="text-align: center">
          <span style="font-size: 20px">Photo</span>
        </div>
        `;
      }
    },
  },
  methods: {
    ...mapActions(["htmlToPDF", "fetchCandidateNonMedicalQuals"]),
    handleInput(name, value) {
      this.payload[name] = value;
    },
    modalCallBack(action, data) {
      this.$emit("modalCallBack", action, data);
    },
    skipFirst(data) {
      if (data.length) {
        return data.slice(1);
      }
      return data;
    },
    getMonthName(month) {
      return month ? m(month, "M").format("MMM") : month;
    },
    saveFrontSheet() {
      let finalPayload = {
        customer_uid: this.getCustomerId,
        organisation_id: this.getOrgIDFromAccessToken,
        job_id: this.jobDetail?.job_id,
        candidate_uid: this.candidateDetail?.candidate_uid,
      };
      if (this.payload) finalPayload = { ...finalPayload, ...this.payload };
      this.modalCallBack(true, finalPayload);
    },
    commentsModalCallBack(action, data) {
      this.commentsModal.isShowPopup = false;
    },
    exportToPDF() {
      setTimeout(() => {
        const pdf_header = "<html>";
        const contentHtml = this.$refs.frontsheet.innerHTML;
        const css_string =
          ".mb-2{margin-bottom:0.5rem;}.w-100{width:100% }.table{margin-bottom: 1rem;color: #4f5d73;border-collapse: collapse;}.table-bordered {border: 1px solid;border-color: #d8dbe0;}.col-label{line-height: 2rem;font-weight: 500;}.table td {padding: 0.75rem;vertical-align: top;border-top: 1px solid;border-top-color: #d8dbe0;}.table-bordered td {border: 1px solid;border-color: #d8dbe0;}.pl-4{padding-left:1rem;}textarea{display:none;}.star-rating{display:none;}h6, .h6 {font-size: 0.875rem;margin-top: 0;margin-bottom: 0.5rem;font-weight: 500;line-height: 1.2;}c{font-size: 1.09375rem;margin-bottom: 0.5rem;}";
        const sourceHTML = `${pdf_header}<style type="text/css">${css_string}</style><body><h5 style='text-align:center'>Frontsheet</h5>${contentHtml}</body></html>`;
        let file_name = `${this.candidateDetail?.first_name}_${this.candidateDetail?.display_uid}_frontsheet`;
        this.htmlToPDF({
          html_string: sourceHTML,
          file_name,
        });
      }, 500);
    },
    htmlTableString(data, title = "") {
      let htmlString = `<div><h4>${title}</h4>
                        <table style='width: 100%; border-collapse: collapse;'>
                          <tbody>`;
      let tbodyString = "";
      data.forEach((v) => {
        if (!v?.is_hide && !v.is_profile_image) {
          tbodyString = `${tbodyString}
        <tr>
          <td style='margin-left: 20px; font-weight: 600; width:40%; border: 1px solid black;'>${v.label}</td>
          <td style='margin-left: 20px; width:60%; padding: 1rem; border: 1px solid black;'>${v.value}</td>
        </tr>`;
        }
        else if(v.is_profile_image){
          tbodyString = `${tbodyString}
          <tr>
            <th colspan="2" style="border: 1px solid black;">
              ${v.value}
            </th>
          </tr>
          `;
        }
      });
      return `${htmlString}${tbodyString}</tbody></table></div>` || "";
    },
    exportToWord() {
      const profileSection = [
        {
          label: '',
          value: this.profileImage,
          is_profile_image: true,
        },
        {
          label: "Candidate",
          value: `<b>${this.candidateDetail.first_name} ${this.candidateDetail.surname}</b> | ${this.candidateDetail.display_uid}`,
        },
        {
          label: "Known As",
          value: `${this.candidateDetail.known_as}`,
          is_hide: !this.candidateDetail.known_as,
        },
        {
          label: "Job Title",
          value: `<b>${this.jobDetail.job_title}</b> | ${this.jobDetail.organisation_name} | ${this.jobDetail.display_uid}`,
        },
        {
          label: "Current Job Title",
          value: `${this.candidateDetail.current_job || "N/A"}`,
        },
        {
          label: "Estimated Join Date",
          value: `${this.formatDate(this.candidateDetail.expected_join_date)}`,
        },
      ];
      const qualificationStr =
        this.qualifications.map(
          (v, index) =>
            `${index + 1}. ${v.qualification} | ${v.country_name} | ${
              v.date_obtained
            }<br>`
        ) || "N/A";
      const nonmedicalqual =
        this.getCandidateNonMedicalQuals.map(
          (v, index) =>
            `${index + 1}. ${v?.non_medical_qual?.name} | ${
              v?.non_medical_qual?.score
            }<br>`
        ) || "N/A";
      const professionalSection = [
        {
          label: "Speciality/Sub Speciality",
          value: `${this.splSub || "N/A"}`,
        },
        {
          label: "Qualification",
          value: qualificationStr,
        },
        {
          label: "Additional Information",
          value: this.payload.additional_information || "N/A",
        },
        {
          label: "Language Qualifications",
          value: `${nonmedicalqual}` || "N/A",
        },
        {
          label: "Other Languages",
          value: this.languages,
        },
      ];
      const practicalities = [
        {
          label: "Employment(s)",
          value: this.payload.employment_s || "",
        },
        {
          label: "Registrations",
          value: this.payload.registrations || "N/A",
        },
        {
          label: "Gender",
          value: this.gender,
        },
        {
          label: "Passport",
          value: `${this.candidateDetail.passport_number || "N/A"}`,
        },
        {
          label: "Birthplace",
          value: this.birthPlace,
        },
        {
          label: "DOB/Age",
          value: `${this.formatDate(this.candidateDetail.dob)} / ${
            this.candidateDetail.age
          } Years`,
        },
        {
          label: "Status/No. of Dependents",
          value: this.candidateDetail.marital_status
            ? `${this.candidateDetail.marital_status}${
                this.candidateDetail.marital_status === "Married"
                  ? ` - ${this.candidateDetail.dependents}`
                  : ""
              }`
            : "N/A",
        },
        {
          label: "Comments",
          value: this.payload.comments || "N/A",
        },
        {
          label: "Current Salary",
          value: this.candidateDetail.current_salary
            ? `${this.candidateDetail.current_salary} ${
                this.candidateDetail?.current_salary_currency?.currency_code ||
                ""
              }`
            : "N/A",
        },
        {
          label: "Expected Salary",
          value:
            this.candidateDetail.salary_expectation_min &&
            this.candidateDetail.salary_expectation_max
              ? `${this.candidateDetail.salary_expectation_min} - ${
                  this.candidateDetail.salary_expectation_max
                } ${
                  this.candidateDetail?.salary_expectation_currency
                    ?.currency_code || ""
                }`
              : "N/A",
        },
        {
          label: "Rating of CV",
          value: this.payload.rating_of_cv || "N/A",
        },
      ];
      setTimeout(() => {
        const doc_header =
          "<html xmlns:o='urn:schemas-microsoft-com:office:office' xmlns:w='urn:schemas-microsoft-com:office:word' xmlns='http://www.w3.org/TR/REC-html40'><head><meta charset='utf-8'><title>Export HTML to Word Document with JavaScript</title></head>";
        // const contentHtml = this.$refs.frontsheet.innerHTML;
        const profileHtml = this.htmlTableString(profileSection, "Profile");
        const professionalHtml = this.htmlTableString(
          professionalSection,
          "Professional Info"
        );
        const practicalitiesHtml = this.htmlTableString(
          practicalities,
          "Practicalities"
        );

        const contentHtml = `${profileHtml}${professionalHtml}${practicalitiesHtml}`;
        const css_string =
          ".mb-2{margin-bottom:0.5rem;}.w-100{width:100% }.table{margin-bottom: 1rem;color: #4f5d73;border-collapse: collapse;}.table-bordered {border: 1px solid;border-color: #d8dbe0;}.col-label{line-height: 2rem;font-weight: 600; background-color: #f1f1f1 !important;}.table td {padding: 0.75rem;vertical-align: top;border-top: 1px solid;border-top-color: #d8dbe0;}.table-bordered td {border: 1px solid;border-color: #d8dbe0;}.pl-4{padding-left:1rem;}textarea{display:none;}.star-rating{display:none;}h6, .h6 {font-size: 0.875rem;margin-top: 0;margin-bottom: 0.5rem;font-weight: 500;line-height: 1.2;}c{font-size: 1.09375rem;margin-bottom: 0.5rem;}";
        const sourceHTML = `${doc_header}<body><h3 style='text-align:center'>Frontsheet</h3>${contentHtml}</body></html>`;
        const file_name = `${this.candidateDetail?.first_name}_${this.candidateDetail?.display_uid}_frontsheet.doc`;
        //Start - Word Download
        let source =
          "data:application/vnd.ms-word;charset=utf-8," +
          encodeURIComponent(sourceHTML);
        let fileDownload = document.createElement("a");
        document.body.appendChild(fileDownload);
        // Create a link to the file
        fileDownload.href = source;
        // Setting the file name
        fileDownload.download = file_name;
        //triggering the function
        fileDownload.click();
        document.body.removeChild(fileDownload);
      }, 500);
    },
    formatDate(date) {
      if (date) return m(date).format("DD-MMM-YYYY");
      return "N/A";
    },
  },
  mounted() {
    if (
      isObject(this.frontSheetInfo) &&
      !isEmptyObjectCheck(this.frontSheetInfo)
    ) {
      this.payload = {
        additional_information: this.frontSheetInfo?.additional_information,
        registrations: this.frontSheetInfo?.registrations,
        employment_s: this.frontSheetInfo?.employment_s,
        comments: this.frontSheetInfo?.comments,
        rating_of_cv: this.frontSheetInfo?.rating_of_cv,
      };
    }
    this.commentsModal.comments = this.frontSheets;
    const { candidate_uid } = this.candidateDetail;
    this.fetchCandidateNonMedicalQuals({
      candidate_uid,
      customer_uid: this.getCustomerId,
    });
  },
  filters: {
    formatDate(date) {
      if (date) return m(date).format("DD-MMM-YYYY");
      return "N/A";
    },
  },
};
</script>
<style lang="scss" scoped>
.col-label {
  line-height: 2rem;
  font-weight: 600;
  background-color: #f1f1f1 !important;
}
</style>
